import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

import { User, Project } from '@app/_models';
import { UserService, AuthenticationService, UploadService, ProjectService } from '@app/_services';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule, MatChip, MatChipList} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";

export interface Upload {
  name: string;
  updated: Date;
  ref: string;
  project: string;
}


@Component({
  selector: 'app-mydata',
  templateUrl: './mydata.component.html',
  styleUrls: ['./mydata.component.css']
})
export class MydataComponent implements OnInit {
  currentUser: User;
  currentUserSubscription: Subscription;
  projects: Project[] = [];
  fixData: boolean;
  fixPw: boolean;
  newPw: string;
  uploads;
  rechnungen;
  /* Upload[] = [
    {
      name: 'Vacation Itinerary',
      updated: new Date('01/29/19'),
      ref: 'www.spiegel.de',
      project: 'ProjektA',
    },
    {
      name: 'Kitchen Remodel',
      updated: new Date('12/30/18'),
      ref: 'www.spiegel.de',
      project: 'ProjektB',
    }
  ];
  */


  constructor(
      private authenticationService: AuthenticationService,
      private userService: UserService,
      private uploadService: UploadService,
      private projectService: ProjectService,
      private http: HttpClient
  ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
          this.currentUser = user;
      });
  }

  ngOnInit() {
    this.loadAllProjects();
    this.loadAllUploads();
    this.loadAllRechnungs();
    this.fixData  = false;
    this.fixPw  = false;
    this.newPw = "";
  }

  private loadAllProjects() {
    console.log("my ID: " + this.currentUser.id);
    this.projectService.getMy(this.currentUser.id).subscribe(projects => {
        this.projects = projects;
    });
    // this.projects = [{name:'ProjektA'}, {name:'ProjektB'}]
  }

  private loadAllUploads() {
    this.uploadService.getAll().subscribe(uploads => {
        console.log(uploads);
        this.uploads = uploads;
    });
  }

  private loadAllRechnungs() {
    this.uploadService.getAllRechnungen().subscribe(rechnungen => {
        console.log(rechnungen);
        this.rechnungen = rechnungen;
    });
  }

  private changePassword(user, newPw) {
    console.log("change password ");
    this.http.post<any>(`${environment.apiUrl}/users/changePassword/`+ user.id, {
                                                                  'password': newPw
                                                                })
    .subscribe();
    this.fixPw = true;
  }

}
