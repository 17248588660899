import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelectModule, MatCardModule, MatDividerModule } from '@angular/material';

import { AlertService } from '@app/_services/alert.service';
import { ProjectService, AuthenticationService } from '@app/_services';

import {Observable} from 'rxjs/Rx';
import {HttpClient} from '@angular/common/http';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';
import { Upload, Project } from '@app/_models';

const URL = `${environment.apiUrl}/save`;

@Component({
  selector: 'app-lnupload',
  templateUrl: './lnupload.component.html',
  styles: [`
      .my-drop-zone { border: dotted 4px lightgray; width: auto; height:12em; text-align:center }
      .nv-file-over { border: dotted 4px green; width: auto; height:12em; text-align:center }
      html, body { height: 100%; }
  `],
  styleUrls: ['./lnupload.component.css']
})
export class LnuploadComponent implements OnInit {
  constructor(private alertService: AlertService, private http: HttpClient, private router: Router, private projectService: ProjectService, private authenticationService: AuthenticationService) { }
  monate: string[] = ['Januar', 'Februar', 'März', 'April', 'Mai',
                      'Juni', 'Juli', 'August', 'September', 'Oktober',
                      'November', 'Dezember'];
  jahre: string[];
  // ng2-file-upload
  public uploader: FileUploader = new FileUploader({
                                                    url: URL,
                                                    // queueLimit for max number files
                                                    queueLimit: 1,
                                                    allowedMimeType: ['pdf', 'application/pdf'], // will be loaded only PDF files
                                                    maxFileSize: 5 * 1024 * 1024, // 4 MB
                                                  });
  public hasBaseDropZoneOver: boolean = false;
  public formUpload: Upload;
  public projektnummern: string[];

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }


  ngOnInit() {
    this.loadyear();
    this.loadProjects();
    this.formUpload = new Upload();
    this.formUpload.project = "";
    this.formUpload.year = "";
    this.formUpload.month = "";
    this.formUpload.comment = "";
    this.formUpload.hrs = "";
  }

  public upload() {
    console.log("Form-Data");
    console.log(this.formUpload.project);
    console.log(this.formUpload.year);
    console.log(this.formUpload.month);
    console.log(this.formUpload.comment);
    console.log(this.formUpload.hrs);
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('projectid', this.formUpload.project); // note comma separating key and value
      form.append('month', this.formUpload.month);
      form.append('year', this.formUpload.year);
      form.append('hrs', this.formUpload.hrs);
      form.append('comment', this.formUpload.comment);
      // form.append('filename', 'TestName');
      // The number of milliseconds between 1 January 1970 00:00:00 UTC and the given date.
      form.append('upload_time', new Date().valueOf()); // expected output: 823230245000
    };
    this.uploader.uploadAll();
    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any): any => {
      const res = JSON.parse(response);
      if(res.result == "success"){
       console.log("response"+(response));
       this.router.navigate(['']);
       this.alertService.success(res.error);
     } else {
      this.alertService.error(res.error);
     }
    };
  }


  private loadyear(): void {
    const years = [];
    const z = (new Date()).getFullYear();
    const a = z - 1;
    for (let i = a; i <= z; i++) {
      years.push(i.toString());
    }
    this.jahre = years;
  }

  private loadProjects() {
    this.projectService.getMy(this.authenticationService.currentUserValue.id).subscribe(res => {
      this.projektnummern = res.map(data => data.name);
    });
  }

}
