﻿import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators, FormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
//import { fakeBackendProvider } from './_helpers';

import { AppComponent }  from './app.component';
import { routing }        from './app.routing';

import { AlertComponent } from './_components';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HomeComponent } from './home';
import { LoginComponent } from './login';
import { RegisterComponent } from './register';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import {MatButtonModule, MatFormFieldModule, MatInputModule, MatDatepickerModule,
  MatNativeDateModule, MatSelectModule, MatCardModule, MatDividerModule, MatExpansionModule} from '@angular/material';
import { LnuploadComponent } from './lnupload/lnupload.component';

import { MydataComponent } from './mydata/mydata.component';
import { RnuploadComponent } from './rnupload/rnupload.component';;
import { DocumentsComponent } from './documents/documents.component';
import { AdminComponent } from './admin/admin.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MsalModule, MsalInterceptor} from '@azure/msal-angular';
import { FileSelectDirective, FileDropDirective, FileUploader, FileUploadModule } from 'ng2-file-upload';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule, MatChip, MatChipList} from '@angular/material/chips';
import {ModalModule} from 'angular-custom-modal';

import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatStepperModule} from '@angular/material/stepper';


@NgModule({
    imports: [
        MatSlideToggleModule,
        MatStepperModule,
        ModalModule,
        MatChipsModule,
        MatIconModule,
        MatListModule,
        FileUploadModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        routing,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatCardModule,
        MatDividerModule,
        MatExpansionModule,
        MsalModule.forRoot({
          // stefan
          clientID: 'abb5a057-987f-498f-9b84-c091713a0e22',
          // working clientID: '3679b92e-5b93-468a-a5ae-dbae78c755ba',
          // azure clientID: '472567c3-a7ff-4f23-9820-00725f66283a',
          redirectUri: 'https://ln.grandega-portal.de/callback',
          // authority: 'https://login.microsoftonline.com/grandega.onmicrosoft.com',
        })

    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        NavbarComponent,
        FooterComponent ,
        LnuploadComponent ,
        MydataComponent ,
        RnuploadComponent ,
        DocumentsComponent,
        AdminComponent,
      ],

    providers: [
        // MSAL
        // { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true},

        // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        // fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
