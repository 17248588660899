﻿import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@environments/environment";
import { User } from "@app/_models";

import { MsalService } from "@azure/msal-angular";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public azureLoginSuccess: boolean;


  public profile;

  constructor(private http: HttpClient, private authService: MsalService) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.azureLoginSuccess = false;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${environment.apiUrl}/users/authenticate`, {
        email: email,
        password: password
      })
      .pipe(
        map(user => {
          if (user) {
            // store user details
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }

          // return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    if (this.currentUserValue.role) {
      if(this.currentUserValue.role === 'intern' || this.currentUserValue.role === 'admin') {
        this.azureLoginSuccess = false;
        this.authService.logout();
      }
    }
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);

    this.http.get<any>(`${environment.apiUrl}/logout`).subscribe(res => console.log(res));
  }

  azure_login(payload) {
    // console.log("Token set:" + payload["_token"]);
    // localStorage.setItem("currentToken", payload["_token"]);
    var token = payload['_token'];
    console.log('Registering with api');
    return this.http
      .post<any>(`${environment.apiUrl}/loginJWT`, { token: token })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
          }
          console.log(user);
          return user;
        })
      );
  }

  getAzureProfile() {
    return this.http.get<any>('https://graph.microsoft.com/v1.0/me').subscribe(
      res => {
        console.log(res);
      },
      error => {
        console.log(error.message);
      }
    );
  }
}
