import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class UploadService {
    constructor(private http: HttpClient) { }

    // show all uploads
    getAll() {
        return this.http.get<any>(`${environment.apiUrl}/data`);
    }

    update(id: number, data) {
        return this.http.put(`${environment.apiUrl}/data/` + id, data);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/data/` + id);
    }

    getAllRechnungen() {
        return this.http.get<any>(`${environment.apiUrl}/rechnungen`);
    }

    getAllPublicFiles() {
        return this.http.get<any>(`${environment.apiUrl}/public`);
    }

    getPublicFile(filename) {
        return this.http.get<any>(`${environment.apiUrl}/public/${filename}`);
    }
}
