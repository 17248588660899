﻿import { Project } from "./project";

export class User {
    id: number;
    email: string;
    password: string;
    firstname: string;
    lastname: string;
    role: string;
    token: string;
    projects: Project[];
    name: string;
}

// changed firstName und lastName zu firstname und lastname
// TODO check if complications
