import { Component, OnInit } from '@angular/core';
import {MatListModule} from '@angular/material/list';
import { UploadService } from '@app/_services';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {

  documents = [];

  constructor(private uploadService: UploadService) { }

  ngOnInit() {
    this.uploadService.getAllPublicFiles().subscribe(documents => {
      this.documents = documents;
      // TODO Why replacement not working??
      this.documents.map(path => path.replace("public/", ""));
      console.log(documents);
    });
  }

}
